<script>
import { mapGetters } from 'vuex';

import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import { SearchBar } from '~/features/search';

import Sidebar from '@/components/molecules/Sidebar';
import { useDeleteActiveSessions } from '~/api/useDeleteActiveSessions';

const layoutHeaders = {
  formTemplate: defineAsyncComponent(() =>
    import('~/features/form-template/components/Heading'),
  ),
  formTemplateCreate: defineAsyncComponent(() =>
    import('~/features/form-template/views/FormTemplateCreate/Heading'),
  ),
};

export default defineNuxtComponent({
  setup() {
    const { mutate: deleteSessions } = useDeleteActiveSessions();

    return { deleteSessions };
  },
  components: { Sidebar, SearchBar },
  mixins: [SidebarMixin],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
    navigation: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    checkedLocation: {
      type: Number,
      required: true,
      default: 0,
    },
    currentModule: {
      type: String,
      required: true,
    },
    canCreateNew: {
      type: Boolean,
      default: false,
    },
    activeSessionCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('SidebarStore', ['selectedNavigationItem']),
    layoutHeader() {
      return layoutHeaders[this.currentModule];
    },
    bothSidebarsCollapsed() {
      return this.isSidebarCollapsed && this.isSecondSidebarCollapsed;
    },
  },
  methods: {
    onNavigationItemClick() {
      this.setSecondSidebarIsCollapsed(
        !this.selectedNavigationItem.children?.length,
      );
    },
    onLocationChanged(location) {
      this.$emit('onLocationChanged', location);
    },
    onCreateNew() {
      this.$emit('onCreateNew');
    },
  },
});
</script>

<template>
  <div class="flex h-full">
    <Sidebar
      :checked-location="checkedLocation"
      :locations="locations"
      :navigation="navigation"
      :user="user"
      @on-location-changed="onLocationChanged"
      @on-navigation-item-click="onNavigationItemClick"
    />
    <div
      class="flex h-full w-full flex-col overflow-y-auto transition-all duration-200"
      :class="{
        'pl-26': bothSidebarsCollapsed,
        'pl-80': !isSidebarCollapsed && isSecondSidebarCollapsed,
      }"
    >
      <div v-if="activeSessionCount > 1" class="w-100 bg-chevron-red p-2 text-center font-bold text-white">
        {{ sprintf(__('Multiple active sessions detected on %s devices'), activeSessionCount) }}.
        {{ __('If unauthorized access is suspected, log out of all active sessions') }}.
        <span class="cursor-pointer underline" @click="deleteSessions">{{ __('Log Out All Sessions') }}</span>
      </div>

      <div class="flex items-center px-20 py-10">
        <SearchBar
          label=""
          :placeholder="__('What are you looking for?')"
          :selected-location="checkedLocation"
        />
        <trailblazer-button
          v-if="canCreateNew"
          variant="primary"
          class="ml-6"
          @click="onCreateNew"
        >
          {{ __('Create new') }}
        </trailblazer-button>
      </div>
      <KeepAlive :max="3">
        <div
          :class="{
            'flex h-full flex-col overflow-y-auto px-18 pt-1':
              !$route.fullPath.includes('form-flows'),
            'h-full': $route.fullPath.includes('form-flows'),
          }"
        >
          <component :is="layoutHeader" />

          <NuxtPage />
        </div>
      </KeepAlive>
    </div>
  </div>
</template>
